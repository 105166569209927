<template>  
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center my-3 py-5">
            <div>
              <div class="row justify-content-center">
                <div class="col-10">
                  <div class="error-img">
                   <div id="lottieDiv" class="img-fluid mx-auto d-block"></div>
                   
                  </div> 
                 
                </div>
              </div>
            </div>
             
            <h1 class="text-uppercase mt-4">{{ $t('404_title') }}</h1>
            <p class="text-muted">
              {{ $t('404_content') }} 
            </p>
            <div class="my-5">
              <router-link to="/" class="btn btn-primary"
                >{{ $t('404_back') }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div> 
    <Common ref="commonFunc"/>
  </div>
</template>
<script>
import Common from "@/components/common";
import appConfig from "@/app.config";
/**
 * 404-component
 */
export default {
  components: { Common },
  page: {
    title: "Error 404",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Error-404",
      path:'animate/404.json',
      showLottie:false
    };
  },
  mounted(){
        var svgContainer = document.getElementById('lottieDiv');
        var animation = window.bodymovin.loadAnimation({
          container: svgContainer,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: this.path
        })
        animation.play()
  },
  methods:{
  }
};
</script>
