<script>
import appConfig from "@/app.config";
import { EncryptStorage } from 'encrypt-storage';
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});
export default {
  props: {
    
  },
  watch:{
  },
  data() {
    return {
      title: ""
    }
  },
  mounted(){
    this.getSetLang()
  },
  created(){
    
  },
  methods:{
    setLang(lang){
     encryptStorage.setItem('lang', lang);
     this.$i18n.locale = lang
    },
    getSetLang(){
      const lang = encryptStorage.getItem('lang');
      console.log(lang)
      this.$i18n.locale = lang
      return lang
    },
    getPreviosLink(){
      return encryptStorage.getItem('PL');
    },

    setData(data){
     encryptStorage.setItem('token', data.access_token);
     encryptStorage.setItem('phone', data.phone);
     encryptStorage.setItem('userdata',JSON.stringify(data));
     encryptStorage.setItem('lang', data.lang);
     encryptStorage.setItem('loanStatus', data.status);
    // encryptStorage.setItem('token-not-encrypted', 'edbe38e0-748a-49c8-9f8f-b68f38dbe5a2', true);
    },
    setServiceData(data){
     encryptStorage.setItem('service',JSON.stringify(data));
    },
    setArticleData(data){
     encryptStorage.setItem('article',JSON.stringify(data));
    },
    getServiceData(){
      var data = encryptStorage.getItem('service')|| []
      return data;
    },
    updateData(val){
      var data = this.getFullData()
      var dataObj = JSON.parse(val)
      console.log(dataObj)
      for (let key in dataObj) {
        data[key] = dataObj[key];
      }
      encryptStorage.setItem('userdata',JSON.stringify(data))
    },
    getToken(){
      var data = encryptStorage.getItem('userdata')|| {
        access_token:""
      }
      return data.access_token || ""
    },
    getPhone(){
      var data = encryptStorage.getItem('userdata') || {
        phone:""
      }
      return data.phone || ""
    },
    getLoanStatus(){
      var data = encryptStorage.getItem('userdata') || {
        loanStatus:99
      }
      return data.loanStatus
    },
    getFullData(){
      var data = encryptStorage.getItem('userdata')|| {}
      return data;
    },
    setProfileInfo(data){
     encryptStorage.setItem('profileInfo',JSON.stringify(data));
    },
    updateProfileInfo(val){
      var data = this.getLoanInfo()
      var dataObj = JSON.parse(val)
      console.log(dataObj)
      for (let key in dataObj) {
        data[key] = dataObj[key];
      }
      encryptStorage.setItem('profileInfo',JSON.stringify(data))
    },
    getProfileInfo(){
      var data = encryptStorage.getItem('profileInfo')|| {}
      return data;
    },

    clearData(){
      encryptStorage.removeMultipleItems(['token','phone','userdata','profileInfo','loanStatus']);
    },
    clear(){
      encryptStorage.clear();
    },
    // getData(){
    //   const value = encryptStorage.getMultipleItems(['token', 'user', 'token-not-encrypted']);
    //   const value2 = encryptStorage.getItem('token');
    //   console.log(JSON.stringify(value))
    //   console.log(value2)
    // },
    convertCurrencyFormat(val){
      const number = val
      const currencyFormat = number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits:2,
      maximumFractionDigits:2,
      });
      const currencyWithoutSymbol = currencyFormat.replace('$', '');
      return currencyWithoutSymbol
    }
  }
};
</script>

<template>
  <!-- start page title -->
  <div></div>
  <!-- end page title -->
</template>